
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string().min(3).required().label("Usuario"),
      password: Yup.string().min(4).required().label("Contraseña"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          router.push({ name: store.getters.isAdmin ? "admin_dashboard" : (store.getters.isMutualAdmin ? "mutual_dashboard" : "mutual_dashboard") });
        })
        .catch((err) => {
          displayErrors(store, "AuthModule");
          submitButton.value?.removeAttribute("data-kt-indicator");
          submitButton.value!.disabled = false;
        });
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
